<template>
  <div class="educTools">
    <!-- PC端 -->
    <div v-if="viewData.length>0" class="educTools-box-pc">
      <div class="item-top">
        <div v-for="(item,index) in viewData" :key="index" class="item" @click="openDailogDetail(item)">
          <img :src="item.appIconUrl" class="typeImg">
          <p class="item-title">
            {{ item.appName }}
          </p>
          <label class="item-type">
            {{ appType[item.terminalType] }}
          </label>
        </div>
      </div>
      <div v-if="isLastPage==false" class="more-box" @click="loadeMore">
        加载更多
      </div>
    </div>
    <NoneEmpty v-else />
    <el-dialog title="应用详情" :visible.sync="dialogdetailVisible" width="800px">
      <div class="dailog-line1">
        <img :src="detailData.appIconUrl" class="dailogTypeImg">
        <div class="right">
          <p class="title">
            {{ detailData.appName }}
          </p>
          <div class="version">
            当前版本：{{ detailData.editionNo }}
          </div>
        </div>
      </div>
      <div class="dailog-line2">
        <div class="type">
          适用年级：
        </div>
        <div class="val">
          <span v-for="(v,i) in detailData.grades" :key="i"><span v-show="i!==0">、</span>{{ v.name }}</span>
        </div>
      </div>
      <div class="dailog-line2 dailog-line3">
        <div class="type">
          适用学科：
        </div>
        <div class="val">
          <span v-for="(v,i) in detailData.subjects" :key="i"><span v-show="i!==0">、</span>{{ v.name }}</span>
        </div>
      </div>
      <div class="dailog-line2 dailog-line3">
        <div class="type">
          应用简介：
        </div>
        <div class="val">
          {{ detailData.introduction }}
        </div>
      </div>
      <div class="dailog-line2">
        <div class="type">
          应用详情：
        </div>
        <div class="val" v-html="detailData.details" />
      </div>
    </el-dialog>
    <!-- mobile -->
    <div class="educTools-box-mobile">
      <div v-for="(item,index) in viewData" :key="index" class="item" @click="toMobileDetail(item.id)">
        <img :src="item.appIconUrl" class="typeImg">
        <p class="item-title">
          {{ item.appName }}
        </p>
        <label class="item-type">
          {{ appType[item.terminalType] }}
        </label>
      </div>
    </div>
    <div v-if="isLastPage==false" class="mobile-more-box" @click="loadeMore">
      加载更多
    </div>
  </div>
</template>
<script>
import { getAppList, getDetail } from '@/api/edu.js';
import NoneEmpty from '@/components/baseComon/noneEmpty.vue';
const appType = {
  '1': '联机应用',
  '2': '教师应用',
  '3': '学生应用',
  '4': '家校应用'
};
export default {
  name: 'EducTools',
  components: { NoneEmpty },
  data() {
    return {
      dialogdetailVisible: false,
      viewData: [],
      appType,
      detailData: {},
      pageNum: 1,
      pageSize: 20,
      isLastPage: ''
    };
  },
  mounted() {
    this.getAppList();
  },
  methods: {
    openDailogDetail(data) {
      this.dialogdetailVisible = true;
      this.getDetail(data.id);
    },
    getAppList() {
      getAppList(this.pageNum, this.pageSize).then(res => {
        this.isLastPage = res.data.isLastPage;
        if (this.pageNum > 1) {
          this.viewData = this.viewData.concat(res.data.list);
        } else {
          this.viewData = res.data.list || [];
        }
      });
    },
    getDetail(id) {
      getDetail(id).then(res => {
        this.detailData = res.data;
      });
    },
    toMobileDetail(id) {
      this.$router.push({ path: '/educ/appDetail', query: { id: id }});
    },
    loadeMore() {
      this.pageNum += 1;
      this.getAppList();
    }

  }
};
</script>
<style lang="scss">
.el-dialog{
  .el-dialog__header{
    background: #F2A23A !important;
  }
  .el-dialog__headerbtn .el-dialog__close {
    color: #333333;
  }
  .el-dialog__body{
    padding: 40px;
  }
}
</style>
<style scoped lang="scss">
@media screen and (min-width: 1200px) {
  .educTools{
    .educTools-box-mobile{
      display: none;
    }
    .mobile-more-box{
      display: none;
    }
    .educTools-box-pc{
      width: 1200px;
      margin: 50px auto;
      .item-top{
        display: flex;
        flex-wrap: wrap;
      }
      .more-box{
        background: #fff;
        text-align: center;
        padding: 20px 0;
        margin-bottom: 60px;
        font-size: 18px;
        font-weight: 400;
        color: #333333;
        border-radius: 10px;
        cursor: pointer;
      }
      .item{
        background: #fff;
        border-radius: 10px;
        width: 200px;
        height: 230px;
        text-align: center;
        margin: 0 50px 40px 0;
        padding: 30px 45px;
        text-align: center;
        &:nth-child(5n){
          margin-right: 0px;
        }
        .item-title{
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          margin: 15px 0 5px 0;
          white-space: nowrap;
          text-overflow:ellipsis;
          overflow: hidden;
        }
        .item-type{
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
        .typeImg{
          width: 110px;
          height: 110px;
        }
      }
    }
    .dailog-line1{
      display: flex;
      align-items: center;
      border-bottom: 1px dashed #F0F0F0;
      padding-bottom: 20px;
      margin-bottom: 20px;
      .dailogTypeImg{
        width: 100px;
        height: 100px;
      }
      .right{
        margin-left: 20px;
        .title{
          font-size: 24px;
          font-weight: bold;
          color: #333333;
        }
        .version{
          font-size: 15px;
          font-weight: 400;
          color: #666666;
        }
      }
    }
    .dailog-line3{
      margin: 15px 0;
    }
    .dailog-line2{
      font-size: 15px;
      color: #333333;
      display: flex;
      .type{
        font-weight: bold;
        width: 12%;
      }
      .val{
        font-weight: 400;
        width: 88%;
      }
    }
  }
}
@media screen and (min-width:992px) and (max-width: 1200px){

}
@media screen and (max-width: 1200px) {
  .educTools-box-pc{
    display: none;
  }
  .educTools-box-mobile{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 15px;
    .item{
      background: #fff;
      border-radius: 16px;
      text-align: center;
      padding: 25px 0;
      width: 47%;
      margin-bottom: 16px;
      .typeImg{
        width: 95px;
        height: 95px;
      }
      .item-title{
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        margin: 12px 0 5px;
      }
      .item-type{
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  .mobile-more-box{
    background: #fff;
    text-align: center;
    padding: 10px 0;
    margin:0 20px 30px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }
}
</style>
